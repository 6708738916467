import {IWidgetController} from '@wix/native-components-infra/dist/es/src/types/types';
import {IControllerFactoryConfig} from '@wix/wixstores-client-core/dist/es/src/viewer-script/createViewerScript';
import {withErrorReporting} from '@wix/wixstores-client-core/dist/src/viewer-script/errorReporter';
import {OrderFlat, orderMapper} from '@wix/wixstores-platform-common/dist/src';
import {thankYouPagePreviewModeDefaultOrder} from './defaultOrder';

export function thankYouPageController({reportError, context}: IControllerFactoryConfig): IWidgetController {
  const {siteStore} = context;

  const wixCodeGetOrder = (): Promise<OrderFlat> => {
    if (siteStore.isPreviewMode()) {
      return Promise.resolve(orderMapper(thankYouPagePreviewModeDefaultOrder()));
    } else {
      const {path} = siteStore.location;
      if (path && path.length >= 2) {
        const orderId = path[1];
        return fetchOrder(orderId);
      } else {
        return Promise.reject('missing order id');
      }
    }
  };

  const fetchOrder = async (orderId: string) => {
    const query = {
      method: 'get',
      headers: {Authorization: siteStore.instance},
    };

    const response = await fetch(siteStore.resolveAbsoluteUrl(`/_api/orders-server/v2/orders/${orderId}`), query);
    const json = await response.json();
    if (json && json.order) {
      return orderMapper(json.order);
    }
  };

  return {
    pageReady: () => {
      return Promise.resolve();
    },
    exports: () =>
      withErrorReporting(reportError)({
        getOrder: (): Promise<OrderFlat> => {
          return wixCodeGetOrder();
        },
      }),
  };
}
